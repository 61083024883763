import React, { useState } from 'react';
import { IonAccordion, IonAccordionGroup, IonButton, IonButtons, IonContent, IonHeader, IonItem, IonLabel, IonList, IonMenu, IonMenuButton, IonModal, IonPage, IonSegment, IonSegmentButton, IonTitle, IonToolbar } from '@ionic/react';
import TableContent from '../components/TableContent';
import NewTableComponent from '../components/NewTableComponent';
import baseData from '../res/base.json';
import DativRules from '../components/textTemplated/DativRules';
import AkkuzativRules from '../components/textTemplated/AkkuzativRules';
import NominativRules from '../components/textTemplated/NominativRules';

const Cheats = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState(null);

  const openModalWithComponent = (component) => {
    setSelectedComponent(component);
    setIsOpen(true);
  };

  return (
    <>
    <IonMenu side="end" contentId="main-content">
        <IonHeader>
          <IonToolbar>
            <IonTitle>Menu Content</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonList>
            <IonItem>
              <IonLabel>A1</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>A2</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>B1</IonLabel>
            </IonItem>
          </IonList>
        </IonContent>
    </IonMenu>
    <IonPage id="main-content">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Hinweise und Hilfe</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>

        <IonSegment value="default">
          <IonSegmentButton value="default">
            <IonLabel>Default</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="segment" >
            <IonLabel>Personal</IonLabel>        
          </IonSegmentButton>
        </IonSegment>

        <div className='ion-padding-top'>
          <h5 className='ion-padding-start'>Basis</h5>
          <IonAccordionGroup>
            <IonAccordion value="first">
              <IonItem slot="header" color="light">
                <IonLabel>Pronomen</IonLabel>
              </IonItem>
              <div slot="content">
                <TableContent data={baseData.pronomen} />
              </div>
            </IonAccordion>
            <IonAccordion value="first01">
              <IonItem slot="header" color="light">
                <IonLabel>Possessivartikel</IonLabel>
              </IonItem>
              <div slot="content">
                <NewTableComponent data={baseData.possessivartikel.Nominativ} />
                <IonAccordionGroup>
                  <IonAccordion value="child01">
                    <IonItem slot="header" color="secondary">
                        <IonLabel>Possessivartikel (Akkusativ.)</IonLabel>
                      </IonItem>
                      <div slot="content">
                      <NewTableComponent data={baseData.possessivartikel.Akkusativ} />
                      </div>
                  </IonAccordion>
                  <IonAccordion value="child02">
                    <IonItem slot="header" color="secondary">
                        <IonLabel>Possessivartikel (Dativ.)</IonLabel>
                      </IonItem>
                      <div slot="content">
                      <NewTableComponent data={baseData.possessivartikel.Dativ} />
                      </div>
                  </IonAccordion>
                </IonAccordionGroup>
              </div>
            </IonAccordion>
            <IonAccordion value="second">
              <IonItem slot="header" color="light">
                <IonLabel>Frages</IonLabel>
              </IonItem>
              <div slot="content">
                <TableContent data={baseData.frage} />
              </div>
            </IonAccordion>
            <IonAccordion value="third">
              <IonItem slot="header" color="light">
                <IonLabel>Sein (быть), Haben (иметь)</IonLabel>
              </IonItem>
              <div slot="content">
                <TableContent data={baseData.seinHaben.praesens} />
                <IonAccordionGroup>
                  <IonAccordion value="child1">
                    <IonItem slot="header" color="secondary">
                        <IonLabel>Praeteritum (прошедш.)</IonLabel>
                      </IonItem>
                      <div slot="content">
                        <TableContent data={baseData.seinHaben.praeteritum} />
                      </div>
                  </IonAccordion>
                  <IonAccordion value="child2">
                    <IonItem slot="header" color="secondary">
                        <IonLabel>Perfect (прошедш.+)</IonLabel>
                      </IonItem>
                      <div slot="content">
                        <TableContent data={baseData.seinHaben.perfect} />
                      </div>
                  </IonAccordion>
                  <IonAccordion value="child3">
                    <IonItem slot="header" color="secondary">
                        <IonLabel>Plusquamperfekt (прошедш.++)</IonLabel>
                      </IonItem>
                      <div slot="content">
                        <TableContent data={baseData.seinHaben.plusquamperfekt} />
                      </div>
                  </IonAccordion>
                </IonAccordionGroup>
              </div>
            </IonAccordion>
          </IonAccordionGroup>
        </div>

        <div className='ion-padding-top'>
          <h5 className='ion-padding-start'>Rules</h5>
          <IonAccordionGroup>
          <IonAccordion value="third2">
              <IonItem slot="header" color="light">
                <IonLabel>Nominativ</IonLabel>
              </IonItem>
              <div className="ion-padding" slot="content">
                <p><strong>Nominativ</strong> — это основной падеж в немецком языке</p>
                <p>Он отвечает на вопросы  <strong>Wer? Was?</strong> || <strong>"кто?" "что?"</strong></p>
                <p>Используется для того, чтобы указать на действующее лицо или предмет в предложении.</p>
              <IonButton expand="block" onClick={() => openModalWithComponent(<NominativRules />)}>
                  Open Nominativ Rules
                </IonButton>
              </div>
            </IonAccordion>
            <IonAccordion value="second2">
              <IonItem slot="header" color="light">
                <IonLabel>Akkuzativ</IonLabel>
              </IonItem>
              <div className="ion-padding" slot="content">
                <p><strong style={{color: "blue"}}>Akkusativ</strong> — используется для обозначения прямого дополнения, то есть объекта действия.</p>
                <p>Он отвечает на вопросы  <strong>Wen? Was?</strong> || <strong>"кого?" "что?"</strong></p>
                <p>Используется с определенными глаголами, а также с рядом предлогов.</p>
                <IonButton expand="block" onClick={() => openModalWithComponent(<AkkuzativRules />)}>
                  Open Akkuzativ Rules
                </IonButton>
              </div>
            </IonAccordion>
            <IonAccordion value="first2">
              <IonItem slot="header" color="light">
                <IonLabel>Dativ</IonLabel>
              </IonItem>
              <div className="ion-padding" slot="content">
                <p><strong style={{color: "red"}}>Dativ</strong> — используется для обозначения, кому или чему предназначено действие</p>
                <p>Он отвечает на вопросы  <strong>Wem?</strong> || <strong>"кому?" "чему?"</strong></p>
                <p>Используется с некоторыми предлогами, глаголами.</p>
                <IonButton expand="block" onClick={() => openModalWithComponent(<DativRules />)}>
                  Open Dativ Rules
                </IonButton>
              </div>
            </IonAccordion>
          </IonAccordionGroup>
        </div>


        <IonModal isOpen={isOpen}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Detailed description...</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setIsOpen(false)}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            {selectedComponent}
          </IonContent>
        </IonModal>

      </IonContent>
    </IonPage>
    </>
  );
};

export default Cheats;