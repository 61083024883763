import { IonCol, IonGrid, IonItem, IonItemDivider, IonLabel, IonList, IonRow, IonSegment, IonText } from '@ionic/react'
import React from 'react'

const TableContent = ({ data }) => {
  return (
    <>
    <IonSegment scrollable={true}>
    <IonGrid>
      <IonRow className='no-wrap'>
        {Object.entries(data).map(([caseType, pronouns]) => (
          <IonCol key={caseType}>
            <IonList>
              <IonItemDivider color="medium">
                <IonLabel>{caseType}</IonLabel>
              </IonItemDivider>
              {Object.entries(pronouns).map(([pronoun, translation]) => (
                <IonItem key={pronoun}>
                  <IonLabel class="ion-text-nowrap"><sub>{pronoun}</sub></IonLabel>
                  <IonText class="ion-text-nowrap" color="medium"><sub>({translation})</sub></IonText>
                </IonItem>
              ))}
            </IonList>
          </IonCol>
        ))}
      </IonRow>
    </IonGrid>
    </IonSegment>
    </>
  )
}

export default TableContent