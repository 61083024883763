import { useState } from 'react';
import { IonSegment, IonSegmentButton, IonLabel, IonCard, IonList, IonItem, IonText, IonNote } from '@ionic/react';

const SegmentCardComponent = () => {
  const [selectedSegment, setSelectedSegment] = useState('default');

  const handleSegmentChange = (e) => {
    setSelectedSegment(e.detail.value);
  };

  return (
    <>
      <IonSegment value={selectedSegment} onIonChange={handleSegmentChange}>
        <IonSegmentButton value="default">
          <IonLabel>Default</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="segment">
          <IonLabel>Beispiele</IonLabel>
        </IonSegmentButton>
      </IonSegment>

      {selectedSegment === 'default' && (
        <IonCard>
            <IonList>
                <IonItem>
                    <IonLabel>
                        <IonText>mit</IonText>
                        <br />
                        <IonNote>с</IonNote>
                    </IonLabel>
                    <IonLabel>
                        <IonText>Wie?</IonText>
                        <br />
                        <IonNote>Как?</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>nach</IonText>
                        <br />
                        <IonNote>в, после</IonNote>
                    </IonLabel>
                    <IonLabel>
                        <IonText>Wohin?, Wann?</IonText>
                        <br />
                        <IonNote>Куда?, Когда?</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>bei</IonText>
                        <br />
                        <IonNote>у, рядом с</IonNote>
                    </IonLabel>
                    <IonLabel>
                        <IonText>Wo?</IonText>
                        <br />
                        <IonNote>Где?</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>seit</IonText>
                        <br />
                        <IonNote>с какого времени</IonNote>
                    </IonLabel>
                    <IonLabel>
                        <IonText>Seit wann?</IonText>
                        <br />
                        <IonNote>С какого времени?</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>von</IonText>
                        <br />
                        <IonNote>от, с</IonNote>
                    </IonLabel>
                    <IonLabel>
                        <IonText>Woher?</IonText>
                        <br />
                        <IonNote>Откуда?</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>zu</IonText>
                        <br />
                        <IonNote>к, на</IonNote>
                    </IonLabel>
                    <IonLabel>
                        <IonText>Wohin?</IonText>
                        <br />
                        <IonNote>Куда?</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>zum/zur</IonText>
                        <br />
                        <IonNote>к, на</IonNote>
                    </IonLabel>
                    <IonLabel>
                        <IonText>Wohin?</IonText>
                        <br />
                        <IonNote>Куда?</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>aus</IonText>
                        <br />
                        <IonNote>из</IonNote>
                    </IonLabel>
                    <IonLabel>
                        <IonText>Woher?</IonText>
                        <br />
                        <IonNote>Откуда?</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>vom</IonText>
                        <br />
                        <IonNote>от, с</IonNote>
                    </IonLabel>
                    <IonLabel>
                        <IonText>Woher?</IonText>
                        <br />
                        <IonNote>Откуда?</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>gegenüber</IonText>
                        <br />
                        <IonNote>напротив</IonNote>
                    </IonLabel>
                    <IonLabel>
                        <IonText>Wo?</IonText>
                        <br />
                        <IonNote>Где?</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>außer</IonText>
                        <br />
                        <IonNote>кроме</IonNote>
                    </IonLabel>
                    <IonLabel>
                        <IonText>Wer?</IonText>
                        <br />
                        <IonNote>Кто?</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>entgegen</IonText>
                        <br />
                        <IonNote>навстречу</IonNote>
                    </IonLabel>
                    <IonLabel>
                        <IonText>Wohin?</IonText>
                        <br />
                        <IonNote>Куда?</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>ab</IonText>
                        <br />
                        <IonNote>с какого-то момента</IonNote>
                    </IonLabel>
                    <IonLabel>
                        <IonText>Wann?</IonText>
                        <br />
                        <IonNote>Когда?</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>bei</IonText>
                        <br />
                        <IonNote>у</IonNote>
                    </IonLabel>
                    <IonLabel>
                        <IonText>Wo?</IonText>
                        <br />
                        <IonNote>Где?</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>nach</IonText>
                        <br />
                        <IonNote>в, на</IonNote>
                    </IonLabel>
                    <IonLabel>
                        <IonText>Wohin?</IonText>
                        <br />
                        <IonNote>Куда?</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>zu</IonText>
                        <br />
                        <IonNote>к, на</IonNote>
                    </IonLabel>
                    <IonLabel>
                        <IonText>Wohin?</IonText>
                        <br />
                        <IonNote>Куда?</IonNote>
                    </IonLabel>
                </IonItem>
            </IonList>
        </IonCard>
      )}

      {selectedSegment === 'segment' && (
        <IonCard>
            <IonList>
                <IonItem>
                    <IonLabel>
                        <IonText>Ich fahre mit dem Auto.</IonText>
                        <br />
                        <IonNote>Я еду на машине.</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>Ich fahre nach Berlin.</IonText>
                        <br />
                        <IonNote>Я еду в Берлин.</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>Ich bin bei meinen Freunden.</IonText>
                        <br />
                        <IonNote>Я у своих друзей.</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>Ich wohne seit einem Jahr hier.</IonText>
                        <br />
                        <IonNote>Я живу здесь уже год.</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>Ich komme von der Arbeit.</IonText>
                        <br />
                        <IonNote>Я иду с работы.</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>Ich gehe zu meiner Freundin.</IonText>
                        <br />
                        <IonNote>Я иду к своей подруге.</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>Ich gehe zum Arzt.</IonText>
                        <br />
                        <IonNote>Я иду к врачу.</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>Ich komme aus dem Haus.</IonText>
                        <br />
                        <IonNote>Я выхожу из дома.</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>Er kommt vom Bahnhof.</IonText>
                        <br />
                        <IonNote>Он идёт с вокзала.</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>Das Café liegt gegenüber dem Kino.</IonText>
                        <br />
                        <IonNote>Кафе находится напротив кинотеатра.</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>Außer meinem Bruder kommt niemand.</IonText>
                        <br />
                        <IonNote>Никто не придёт, кроме моего брата.</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>Der Hund läuft mir entgegen.</IonText>
                        <br />
                        <IonNote>Собака бежит мне навстречу.</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>Der Zug fährt ab dem nächsten Montag.</IonText>
                        <br />
                        <IonNote>Поезд отправляется с следующего понедельника.</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>Ich arbeite bei einer großen Firma.</IonText>
                        <br />
                        <IonNote>Я работаю в большой компании.</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>Wir fahren nach Hause.</IonText>
                        <br />
                        <IonNote>Мы едем домой.</IonNote>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonText>Ich gehe zu meinem Freund.</IonText>
                        <br />
                        <IonNote>Я иду к своему другу.</IonNote>
                    </IonLabel>
                </IonItem>
            </IonList>
        </IonCard>
      )}
    </>
  );
};

export default SegmentCardComponent;
